<template>
  <div class="gallery">
    <div
      v-for="item in images"
      class="exhibits"
      :key="item"
      :style="{ backgroundImage: `url(${item})` }"
      @click="$emit('change', item)"
    ></div>
  </div>
</template>

<script>
import presets from "./preset-gallery";

export default {
  props: {
    type: {
      type: String,
      default: "cover",
      validator(value) {
        return !!presets[value];
      }
    }
  },
  computed: {
    images() {
      return presets[this.type];
    }
  }
};
</script>

<style lang="less" scoped>
@size: 110px;
@gutter: 12px;

.gallery {
  width: (@size + @gutter * 2) * 3;
  // 防止高度塌陷
  overflow: hidden;
  .exhibits {
    cursor: pointer;
    width: @size;
    height: @size;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 12px;
    float: left;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.06);
    }
  }
}
</style>
