export const cover = [
  "images/default_cover1.jpg",
  "images/default_cover2.jpg",
  "images/default_cover3.jpg",
  "images/default_cover4.jpg",
  "images/default_cover5.jpg",
  "images/default_cover6.jpg"
];

export const avatar = [
  "images/default_avatar1.svg",
  "images/default_avatar2.svg",
  "images/default_avatar3.svg",
  "images/default_avatar4.svg",
  "images/default_avatar5.svg",
  "images/default_avatar6.svg"
];

export const space = [
  "images/default_space1.svg",
  "images/default_space2.svg",
  "images/default_space3.svg",
  "images/default_space4.svg",
  "images/default_space5.svg",
  "images/default_space6.svg"
];

export default { cover, avatar, space };
