import renderModal from "@/utils/render-modal";
import Modal from "./src/Modal";

/**
 * @typedef {{
 *  title: string,
 *  circle: boolean,
 *  src: string,
 *  text: string,
 *  color: string,
 *  gallery: "cover" | "space" | "avatar",
 * }} CropperConfig
 *
 * @param {CropperConfig} props
 * @returns {Promise<any>}
 */
export default function(props) {
  return renderModal(Modal, props);
}
