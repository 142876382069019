export default {
  INIT_EVENT: "init",
  FILE_CHOOSE_EVENT: "file-choose",
  FILE_SIZE_EXCEED_EVENT: "file-size-exceed",
  FILE_TYPE_MISMATCH_EVENT: "file-type-mismatch",
  FILE_LOADED_EVENT: "file-loaded",
  NEW_IMAGE_EVENT: "new-image",
  NEW_IMAGE_DRAWN_EVENT: "new-image-drawn",
  IMAGE_ERROR_EVENT: "image-error",
  IMAGE_REMOVE_EVENT: "image-remove",
  IMAGE_REMOVE_ONLOAD_EVENT: "image-remove-onload",
  MOVE_EVENT: "move",
  ZOOM_EVENT: "zoom",
  DRAW_EVENT: "draw",
  INITIAL_IMAGE_LOADED_EVENT: "initial-image-loaded",
  LOADING_START_EVENT: "loading-start",
  LOADING_END_EVENT: "loading-end"
};
