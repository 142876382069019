<template>
  <Cropper
    ref="cropper"
    v-bind="cropperOpts"
    @file-size-exceed="onFileSizeExceed()"
    @update="onImageUpdated()"
    @image-remove="$emit('change', '')"
  >
    <!-- cropper 自带的 placeholder 有个毛病，选择文件大小超出限制后就不显示了，烦人 -->
    <span class="cropper-placeholder ndl-text-desc-small">
      请选择小于 2MB 的图片
    </span>
  </Cropper>
</template>

<script>
import debounce from "lodash/debounce";
import Cropper from "./InstagramCropper";

export default {
  components: { Cropper },
  data() {
    return {
      cropperOpts: {
        canvasColor: "transparent",
        placeholder: "",
        // 2MB limits
        fileSizeLimit: 2 * 1024 * 1024,
        preventWhiteSpace: true
      }
    };
  },
  methods: {
    onFileSizeExceed() {
      this.$message.error("请选择小于 2MB 的图片");
    },
    onImageUpdated: debounce(async function() {
      await this.$nextTick();
      /**
       * @type {{ promisedBlob: () => Promise<any> }}
       */
      const ref = this.$refs.cropper;
      const blob = await ref.promisedBlob();
      const url = URL.createObjectURL(blob);
      this.$emit("change", url);
    }, 300)
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.cropper-container {
  width: 240px;
  height: 240px;
  border-radius: 4px;
  border: dashed 1px @ndl-border-color;
  transition: border-color 0.2s;
  overflow: hidden;
  &:hover,
  &.cropper--has-target {
    border-color: @blue-5;
  }
  .cropper-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  &.cropper--has-target .cropper-placeholder {
    display: none;
  }
}
</style>
