<template>
  <a-modal
    class="cropper-modal"
    :title="title"
    :width="650"
    :body-style="{ padding: 0 }"
    :mask-closable="false"
  >
    <Preview
      v-bind="{ circle, src, text, color, newImage }"
      @reset="newImage = null"
    />
    <div class="construction-site">
      <a-radio-group
        v-model="tabKey"
        button-style="solid"
        class="ndl-radio-group"
      >
        <a-radio-button value="Gallery">默认</a-radio-button>
        <a-radio-button value="Tailor">自定义</a-radio-button>
      </a-radio-group>
      <component :is="tabKey" :type="gallery" @change="newImage = $event" />
    </div>
    <a-button slot="footer" @click="$reject()">取消</a-button>
    <a-button
      slot="footer"
      type="primary"
      :disabled="!newImage"
      :loading="loading"
      @click="onConfirm()"
    >
      完成
    </a-button>
  </a-modal>
</template>

<script>
import * as API from "@/api/common";
import Preview from "./ModalPreview";
import Gallery from "./ModalGallery";
import Tailor from "./ModalTailor";

export default {
  inheritAttrs: false,
  components: { Preview, Gallery, Tailor },
  props: {
    title: {
      type: String,
      default: "选择图片"
    },
    // 默认预览图片设置
    circle: Boolean,
    src: String,
    text: String,
    color: String,
    // 不同 gallery 提供不同的默认图片
    gallery: String,
    $resolve: Function,
    $reject: Function
  },
  data() {
    return {
      newImage: "",
      tabKey: "Gallery",
      loading: false
    };
  },
  methods: {
    async onConfirm() {
      this.loading = true;
      const newImage = this.newImage;
      // 相同图片时 reject
      if (!newImage || newImage === this.src) {
        return this.$reject(newImage);
      }
      // 非 blob: 开头的图片路径直接提交
      if (!newImage.startsWith("blob:")) {
        return this.$resolve(newImage);
      }
      try {
        const blob = await fetch(newImage).then(res => res.blob());
        const { data } = await API.uploadFile(blob, "image.png");
        this.$resolve(data.url);
      } catch {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.cropper-modal {
  /deep/ .ant-modal-body {
    display: flex;
  }
  .preview {
    padding: 0 42px;
  }
  .construction-site {
    flex: 1 1 0;
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ant-radio-group {
    width: 180px;
    margin-bottom: 12px;
  }
  .cropper-container {
    margin-top: 12px;
  }
}
</style>
