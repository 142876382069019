import renderModal from "@/utils/render-modal";
import EditName from "./src/EditName";

/**
 * 弹出字符串输入框
 * @typedef {{
 *    title: string,
 *    name: string,
 *    placeholder: string,
 *    okText: string,
 *    width: number | string
 * }} EditorOptions
 * @param {EditorOptions} opts 编辑名称时支持的参数
 * @returns {Promise<any>}
 */
export default function(opts) {
  return renderModal(EditName, opts);
}
