<template>
  <div class="preview">
    <Avatar
      :circle="circle"
      :src="newImage || src"
      :text="text"
      :color="color"
      size="huge"
    />
    <span class="ndl-text-desc-small ndl-padding">预览</span>
    <a v-visible="newImage" @click="$emit('reset')">
      <i class="ndl-icon-rotate-ccw"></i> 取消选择
    </a>
  </div>
</template>

<script>
import Avatar from "@/components/avatar";
import { visible } from "@/utils/directives";

export default {
  components: { Avatar },
  directives: { visible },
  props: {
    circle: Boolean,
    src: String,
    text: [String, Number],
    color: String,
    newImage: String
  }
};
</script>

<style lang="less" scoped>
.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
