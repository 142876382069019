<template>
  <a-modal :width="width" :title="title" :ok-text="okText" @ok="onConfirm()">
    <a-input
      v-model="model"
      v-focus
      :placeholder="placeholder"
      @pressEnter="onConfirm()"
    />
  </a-modal>
</template>

<script>
import { focus } from "@/utils/directives";

export default {
  props: {
    name: String,
    placeholder: {
      type: String,
      default: "请输入名称"
    },
    title: {
      type: String,
      default: "修改名称"
    },
    width: {
      type: [String, Number],
      default: 400
    },
    okText: String,
    $resolve: Function
  },
  directives: { focus },
  data() {
    return {
      model: this.name || null
    };
  },
  methods: {
    onConfirm() {
      if (this.$resolve) {
        this.$resolve(this.model);
      }
    }
  }
};
</script>
